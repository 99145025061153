

















import { Component, Vue, Prop } from 'vue-property-decorator';

import router from '@/router';

import IconUpdate from '@/assets/icons/Update.vue';

const DEFAULT_TEXT = 'Tenha acesso ao conteúdo completo desta matéria, sem limites, por menos de R$1,40 por dia.';
const DEFAULT_NAME_BUTTON = 'Quero ser aluno premium';

@Component({
  components: {
    IconUpdate
  }
})
export default class PlanUpdateSidebar extends Vue {
  @Prop({ default: DEFAULT_TEXT }) text!: string;
  @Prop({ default: DEFAULT_NAME_BUTTON }) nameButton!: string;

  handleRedirectPlan() {
    router.push({
      name: 'Store'
    });
  }
}

