
































import {
  Vue, Component, Prop, Watch 
} from 'vue-property-decorator';

import Checkbox from '@/components/Input/Checkbox/index.vue';
import BoxSidebarContainer from '@/components/BoxSidebarContainer/index.vue';
import Thumb from '@/components/Thumb/index.vue';

import Star from '@/assets/icons/Star.vue';

import store from '@/store';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import { TeacherFilter } from '@/globalInterfaces/Teachers';

@Component({
  components: {
    Checkbox,
    BoxSidebarContainer,
    Thumb,
    Star
  }
})
export default class Teachers extends Vue {
  @Prop() listTeachers!: Array<TeacherFilter>;
  @Prop({ default: true }) showRating!: boolean;
  @Prop({ default: true }) showCheckbox!: boolean;

  @Watch('listTeachers', { deep: true })
  verifyCheckedTeachers() {
    const allIsCheckedOrNotChecked = this.listTeachers.every(
      (teacher: TeacherFilter) => teacher.show || !teacher.show
    );

    if (allIsCheckedOrNotChecked) this.updateListTeachersInStore(this.listTeachers);
  }

  setCheckbox(name: string, checked: boolean, index: number) {
    let { teachers } = store.getters;

    teachers.forEach((teacher: TeacherFilter) => {
      if (teacher.name === name) {
        teachers[index].show = !checked;
        teachers = [...teachers];

        this.setTrackAmplitude(teacher?.name, teacher?.id.toString());
      }
    });

    this.updateListTeachersInStore(teachers);
  }

  setTrackAmplitude(teacherName: string, teacherId: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Teachers',
          local: 'Filtros de professores',
          teacherName,
          teacherId
        }
      }
    });
  }

  updateListTeachersInStore(listTeachers: Array<TeacherFilter>) {
    store.commit('setTeachers', listTeachers);
  }
}
